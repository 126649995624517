.Footer {
  background-color: #1B1B1B;
  color: #ffffff;
  padding: 0rem 1rem;
  font-family: 'Poppins', sans-serif;
}

.Footerboxes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.Footerbox {
  margin: 0.8rem 1.6rem;
}

.Footerboxbox {
  margin-top: 0.8rem;
}

.Footerboxboxtext {
  margin: 0rem;
  font-size: 0.9rem;
  margin-top: 0.2rem;
  color: #D0D0D0;
}

.Footertermsandcopyright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid black;
  padding: 1rem 0rem;
  margin-top: 1.5rem;
}

.Footerterms {
  display: flex;
  flex-direction: row;
  margin-left: 38px;
}

.Footerterm {
  margin-right: 1rem;
  font-size: 0.9rem;
  margin-left: 2.5rem;
}