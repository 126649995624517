.custPad {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 6%;
    padding-bottom: 6%;
}

.sep {
    border-top: 10px solid #ECF0FF;
    border-bottom: 10px solid #ECF0FF;
    background-color: #ECF0FF;
}

.rowPad {
    padding-top: 4%;
}

.custdiv1 {
    padding-top: 6%;
    padding-left: 4%;
    padding-right: 4%;
    align-items: center;
}

.custcarddiv {
    padding-top: 2%;
}

.colPad {
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 1%;
    padding-top: 1%;
}

.custCard {
    width: 18rem
}

.custrow {
    align-items: center;
}

.centerAlign {
    text-align: center;
    padding-top: 2%;
    padding-bottom: 0%;
}

.custPadd {
    padding-top: 7%;
    padding-right: 4%;
    padding-left: 4%;
}

.imgCust {
    height: 9em;
}

.centerAlignPad {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 7%;
}



.animation-div {
    animation: mymove 4s infinite;
}

.custPadText {
    padding-left: 6%;
    padding-top: 4%;
    padding-bottom: 4%;
}

.groupedButtons {
    width: 100%
}

.padding-ul {
    padding-left: 1rem;
}

.Buttons {
    width: 100%;
}


.Contact {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bluesep {
    background-color: #002147;
    color: white;
}

.custPadBot {
    margin-bottom: 2%;
}


.centerImg {
    padding-top: 10%;
    padding-bottom: 5%;
}


.padd {
    padding-top: 2%;
    justify-content: center;
    display: flex;
}

.ulpadd {
    padding-top: 4%;
}

.Title {
    margin-right: 2px;
}

.paddServ {
    padding-top: 6%;
    padding-bottom: 6%;
}

.custFont {
    font-size: 0.8em;
}

.custCenter {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #e7e5e5;
}

.paddcent {
    padding-left: 4%;
}