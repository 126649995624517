.inner {
    display: inline-block;
    bottom: auto;
    top: 40%;
    padding-bottom: 0%;
    padding-top: 0%;
}

h3 {
    font-size: 3vw;
}

.buttonSize {
    display: flex;
    align-items: left;
}