.custom-text {
    color: #fff;
}

.div-custom {
    margin-right: 0;
}

.div-left {
    padding-right: 0;
}

.img-custom {
    border: none;
    padding: 0
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}


.div-right {
    background-image: linear-gradient(325deg, rgb(34, 81, 255) 0%, rgb(5, 28, 44) 100%);
}

.custom-text {
    position: relative;
}

.custom-span {
    position: relative;
    font-size: 1.5vw;
    top: 4vw;
    color: #fff;
    padding-left: 4vw;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.child-div {
    font-size: 3vw;
    font-style: italic;
    z-index: 5;
    line-height: 4vw;
    padding-left: 4vw;
    text-align: left;
    color: #fff;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 6vw;
}

.center-div {
    padding-top: 6vw;
    font-weight: 300;
    font-size: 1.2em;
    padding-bottom: 6vw;
}


@media (min-width: 1200px) {
    .center-div {
        max-width: 750px;
    }
}